import styled, { AnyStyledComponent } from 'styled-components'
import { NavLink, NavLinkProps, To } from 'react-router-dom'
import { useMemo } from 'react'
import { Style, Tune, People } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { Icon } from '@/types/mui'
import { RootState } from '@/utilities/store'
import { LoyaltyAdminRole } from '@/types/api'

export enum Sizes {
  FULL = 256, // px
  COMPACT = 72, // px
}

interface SidebarProps {
  compact?: boolean
  transition?: number // ms
}

interface Link {
  to: To
  Icon: Icon
  title: string
}

interface MainContainerProps {
  compact?: boolean
  transition: number
}

interface NavLinkStyledProps {
  compact?: boolean
}

const MainContainer = styled.ul<MainContainerProps>`
  padding-top: 0.75rem;
  width: ${({ compact }) => (compact ? Sizes.COMPACT : Sizes.FULL)}px;
  transition: width ${({ transition }) => transition}ms;
  overflow: hidden;
`

const NavLinkStyled = styled(NavLink as AnyStyledComponent).attrs<NavLinkStyledProps>({
  style: ({ isActive }) => ({ color: isActive ? '#1976d2' : '#000000' }),
} as NavLinkProps)<NavLinkStyledProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1.5rem;
  padding: 0.75rem 1.5rem;

  ${({ compact }) => !!compact && `grid-template-columns: auto;`}

  :hover {
    background-color: rgba(191, 191, 191, 0.15);
  }
`

const Sidebar = ({ compact, transition = 500 }: SidebarProps) => {
  const user = useSelector((state: RootState) => state.authentication.user)

  const links = useMemo((): Link[] => {
    switch (user?.role) {
      case LoyaltyAdminRole.User:
        return [{ to: '/cards', Icon: Style, title: 'Cards' }]
      case LoyaltyAdminRole.Admin:
        return [
          { to: '/cards', Icon: Style, title: 'Cards' },
          { to: '/customizations', Icon: Tune, title: 'Customizations' },
          { to: '/users', Icon: People, title: 'Users' },
        ]
      case LoyaltyAdminRole.Master:
        return [{ to: '/users', Icon: People, title: 'Users' }]
      default:
        return []
    }
  }, [user?.role])

  return (
    <MainContainer compact={compact} transition={transition}>
      {links.map(({ Icon, title, to }, index) => (
        <li key={index}>
          <NavLinkStyled to={to} compact={compact}>
            <Icon />
            {!compact && <Typography>{title}</Typography>}
          </NavLinkStyled>
        </li>
      ))}
    </MainContainer>
  )
}

export default Sidebar
