import { Models } from '@rematch/core'

import { authentication } from '@/models/authentication'
import { cards } from '@/models/cards'
import { customizations } from '@/models/customizations'
import { images } from '@/models/images'
import { users } from '@/models/users'
import { admins } from '@/models/admins'
import { organizations } from '@/models/organizations'
import { fonts } from '@/models/fonts'

export interface RootModel extends Models<RootModel> {
  authentication: typeof authentication
  cards: typeof cards
  customizations: typeof customizations
  images: typeof images
  users: typeof users
  admins: typeof admins
  organizations: typeof organizations
  fonts: typeof fonts
}

export const models: RootModel = { authentication, cards, customizations, images, users, admins, organizations, fonts }
