import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import { api } from '@/utilities/api'
import { StringContract } from '@/types/api'

interface ImagesState {}

const initialState: ImagesState = {}

export const images = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return initialState
    },
  },
  effects: () => ({
    async uploadImage(file: File) {
      const res: AxiosResponse<StringContract> = await api.post(
        '/uploadImage',
        { file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return res.data
    },
  }),
})
