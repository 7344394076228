import { forwardRef } from 'react'
import QRCode from 'react-qr-code'
import styled, { AnyStyledComponent } from 'styled-components'

interface CardQrCodeProps {
  code: string
}

const Container = styled.div`
  display: none;

  @media print {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const QRCodeStyled = styled(QRCode as unknown as AnyStyledComponent)`
  width: 30rem;
  height: 30rem;
`

const CardQrCode = forwardRef<HTMLDivElement, CardQrCodeProps>(({ code }, ref) => {
  return (
    <Container ref={ref}>
      <QRCodeStyled value={code} />
    </Container>
  )
})

export default CardQrCode
