import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { isAxiosError } from 'axios'

import { Dispatch, RootState } from '@/utilities/store'
import { Spinner } from '@/components'
import { HEADER_HEIGHT } from '@/components/Header'
import { useWindowSizes } from '@/utilities/hooks'
import UsersList from './components/UsersList'
import CreateAdminDialog from './components/CreateAdminDialog'
import CreateUserDialog from './components/CreateUserDialog'
import UpdateAdminDialog from './components/UpdateAdminDialog'
import UpdateUserDialog from './components/UpdateUserDialog'
import ChangeUserPasswordDialog from './components/ChangeUserPasswordDialog'
import {
  ErrorContract,
  LoyaltyAdminUpdateContract,
  LoyaltyAdminRole,
  LoyaltyAdminUpdatePasswordContract,
  RegisterLoyaltyAdminContract,
  RegisterLoyaltyUserContract,
} from '@/types/api'

const Users = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [initLoading, setInitLoading] = useState<boolean>(true)
  const [createAdminDialogOpen, setCreateAdminDialogOpen] = useState<boolean>(false)
  const [updateAdminDialogOpen, setUpdateAdminDialogOpen] = useState<boolean>(false)
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState<boolean>(false)
  const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState<boolean>(false)
  const [changeUserPasswordDialogOpen, setChangeUserPasswordDialogOpen] = useState<boolean>(false)

  const admins = useSelector((state: RootState) => state.admins.admins)
  const users = useSelector((state: RootState) => state.users.users)
  const user = useSelector((state: RootState) => state.authentication.user)

  const registerAdminLoading = useSelector((state: RootState) => state.loading.effects.admins.registerAdmin)
  const registerUserLoading = useSelector((state: RootState) => state.loading.effects.users.registerUser)
  const updateAdminRestaurantsLoading = useSelector(
    (state: RootState) => state.loading.effects.admins.updateAdminRestaurants,
  )
  const changeUserPasswordLoading = useSelector((state: RootState) => state.loading.effects.users.changeUserPassword)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const toggleCreateAdminDialog = () => {
    setCreateAdminDialogOpen((prevCreateAdminDialogOpen) => !prevCreateAdminDialogOpen)
  }

  const toggleUpdateAdminDialog = () => {
    setUpdateAdminDialogOpen((prevUpdateAdminDialogOpen) => !prevUpdateAdminDialogOpen)
  }

  const toggleCreateUserDialog = () => {
    setCreateUserDialogOpen((prevCreateUserDialogOpen) => !prevCreateUserDialogOpen)
  }

  const toggleUpdateUserDialog = () => {
    setUpdateUserDialogOpen((prevUpdateUserDialogOpen) => !prevUpdateUserDialogOpen)
  }

  const toggleChangeUserPasswordDialog = () => {
    setChangeUserPasswordDialogOpen((prevChangeUserPasswordDialogOpen) => !prevChangeUserPasswordDialogOpen)
  }

  const init = async () => {
    try {
      switch (user?.role) {
        case LoyaltyAdminRole.Master:
          await Promise.all([dispatch.admins.getAdmins(), dispatch.organizations.getOrganizations()])
          return
        case LoyaltyAdminRole.Admin:
          await Promise.all([dispatch.users.getUsers(), dispatch.customizations.getCustomizations()])
          return
        default:
          return
      }
    } catch (error) {
      console.error(error)
    } finally {
      setInitLoading(false)
    }
  }

  const handleAdminCreate = async (data: RegisterLoyaltyAdminContract) => {
    try {
      await dispatch.admins.registerAdmin(data)

      toggleCreateAdminDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleUserCreate = async (data: RegisterLoyaltyUserContract) => {
    try {
      await dispatch.users.registerUser(data)

      toggleCreateUserDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleAdminUpdate = async ({ adminId, data }: { adminId: string; data: LoyaltyAdminUpdateContract }) => {
    try {
      await dispatch.admins.updateAdminRestaurants({ adminId, payload: data })

      toggleUpdateAdminDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleUserUpdate = async ({ userId, data }: { userId: string; data: LoyaltyAdminUpdateContract }) => {
    try {
      await dispatch.users.updateUserRestaurants({ userId, payload: data })

      toggleUpdateUserDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleAdminItemClick = (adminId: string) => {
    setSelectedUserId(adminId)

    toggleUpdateAdminDialog()
  }

  const handleUserItemClick = (userId: string) => {
    setSelectedUserId(userId)

    toggleUpdateUserDialog()
  }

  const handleChangeUserPasswordClick = () => {
    toggleUpdateUserDialog()

    toggleChangeUserPasswordDialog()
  }

  const handleChangeUserPasswordDialogClose = () => {
    toggleChangeUserPasswordDialog()

    toggleUpdateUserDialog()
  }

  const handleUserPasswordChange = async ({
    userId,
    data,
  }: {
    userId: string
    data: LoyaltyAdminUpdatePasswordContract
  }) => {
    try {
      await dispatch.users.changeUserPassword({ userId, payload: data })

      toggleChangeUserPasswordDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (initLoading) {
    return (
      <Box height={sizes.height - HEADER_HEIGHT - 48 * 2} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  switch (user?.role) {
    case LoyaltyAdminRole.Master:
      return (
        <>
          <Button variant="contained" onClick={toggleCreateAdminDialog}>
            Create admin
          </Button>

          <Box pt={4}>
            <UsersList users={admins} onItemClick={handleAdminItemClick} />
          </Box>

          <CreateAdminDialog
            open={createAdminDialogOpen}
            loading={registerAdminLoading}
            onCreate={handleAdminCreate}
            onClose={toggleCreateAdminDialog}
          />

          <UpdateAdminDialog
            open={updateAdminDialogOpen}
            selectedAdminId={selectedUserId}
            loading={updateAdminRestaurantsLoading}
            onUpdate={handleAdminUpdate}
            onClose={toggleUpdateAdminDialog}
          />
        </>
      )
    case LoyaltyAdminRole.Admin:
      return (
        <>
          <Button variant="contained" onClick={toggleCreateUserDialog}>
            Create user
          </Button>

          <Box pt={4}>
            <UsersList users={users} onItemClick={handleUserItemClick} />
          </Box>

          <CreateUserDialog
            open={createUserDialogOpen}
            loading={registerUserLoading}
            onCreate={handleUserCreate}
            onClose={toggleCreateUserDialog}
          />

          <UpdateUserDialog
            open={updateUserDialogOpen}
            selectedUserId={selectedUserId}
            loading={updateAdminRestaurantsLoading}
            onUpdate={handleUserUpdate}
            onClose={toggleUpdateUserDialog}
            onChangePasswordClick={handleChangeUserPasswordClick}
          />

          <ChangeUserPasswordDialog
            open={changeUserPasswordDialogOpen}
            selectedUserId={selectedUserId}
            loading={changeUserPasswordLoading}
            onPasswordChange={handleUserPasswordChange}
            onClose={handleChangeUserPasswordDialogClose}
          />
        </>
      )
    default:
      return null
  }
}

export default Users
