import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { RootState } from '@/utilities/store'
import { LoyaltyAdminRole } from '@/types/api'
import { DisabledUserDialog } from '@/components'
import { selectIsUserRole } from '@/models/authentication'

const ProtectedRoutes = () => {
  const [disabledUserDialogOpen, setDisabledUserDialogOpen] = useState<boolean>(false)

  const user = useSelector((state: RootState) => state.authentication.user)

  const isUserRole = useSelector(selectIsUserRole)

  const location = useLocation()

  useEffect(() => {
    if (!isUserRole || !user?.isDisabled) {
      return
    }

    setDisabledUserDialogOpen(true)
  }, [isUserRole, user?.isDisabled])

  if (!user) {
    return <Navigate to="/" replace />
  }

  switch (user.role) {
    case LoyaltyAdminRole.User:
      if (location.pathname === '/users' || location.pathname === '/customizations') {
        return <Navigate to="/cards" replace />
      }
      break
    case LoyaltyAdminRole.Master:
      if (location.pathname === '/customizations' || location.pathname === '/cards') {
        return <Navigate to="/users" replace />
      }
      break
    default:
      break
  }

  return (
    <>
      <Outlet />

      <DisabledUserDialog open={disabledUserDialogOpen} onClose={setDisabledUserDialogOpen} />
    </>
  )
}

export default ProtectedRoutes
