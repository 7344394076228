import { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material'
import { useSelector } from 'react-redux'

import {
  CreateAnonymousLoyaltyCardBulkContract,
  CreateLoyaltyCardBulkContract,
  LoyaltyAdminRole,
  ValidationErrorContract,
} from '@/types/api'
import PersonalCardForm, { PersonalCardFormRef } from './PersonalCardForm'
import AnonymousCardForm, { AnonymousCardFormRef } from './AnonymousCardForm'
import { RootState } from '@/utilities/store'
import { Item } from '@/components/Form'
import { selectCustomizationsObj } from '@/models/customizations'

interface CreateCardDialogProps {
  open: boolean
  loading?: boolean
  onPersonalCardCreate?: (
    data: CreateLoyaltyCardBulkContract,
  ) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onAnonymousCardCreate?: (
    data: CreateAnonymousLoyaltyCardBulkContract,
  ) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

const CreateCardDialog = ({
  open,
  loading,
  onPersonalCardCreate,
  onAnonymousCardCreate,
  onClose,
}: CreateCardDialogProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

  const user = useSelector((state: RootState) => state.authentication.user)
  const customizations = useSelector((state: RootState) => state.customizations.customizations)

  const customizationsObj = useSelector(selectCustomizationsObj)

  const personalCardFormRef = useRef<PersonalCardFormRef>(null)
  const anonymousCardFormRef = useRef<AnonymousCardFormRef>(null)

  const customizationItems = useMemo((): Item[] | undefined => {
    if (user?.role === LoyaltyAdminRole.User) {
      const customizationId = user.customizationId
      if (!customizationId) {
        return
      }

      const customization = customizationsObj[customizationId]
      if (!customization) {
        return
      }

      return [{ value: customization.id!, label: customization.name ?? '' }]
    }

    return customizations.map((customization) => ({ value: customization.id!, label: customization.name ?? '' }))
  }, [customizations, customizationsObj, user?.customizationId, user?.role])

  const handleCreate = () => {
    switch (activeTabIndex) {
      case 0:
        personalCardFormRef.current?.createCard()
        return
      case 1:
        anonymousCardFormRef.current?.createCard()
        return
      default:
        return
    }
  }

  const renderForm = () => {
    switch (activeTabIndex) {
      case 0:
        return (
          <PersonalCardForm
            ref={personalCardFormRef}
            loading={loading}
            customizationItems={customizationItems}
            onCreate={onPersonalCardCreate}
          />
        )
      case 1:
        return (
          <AnonymousCardForm
            ref={anonymousCardFormRef}
            loading={loading}
            customizationItems={customizationItems}
            onCreate={onAnonymousCardCreate}
          />
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (!open) {
      return
    }

    setActiveTabIndex(0)
  }, [open])

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create card</DialogTitle>

      <DialogContent>
        <Tabs value={activeTabIndex} centered onChange={(_, value) => setActiveTabIndex(value)}>
          <Tab label="Personal" />
          <Tab label="Anonymous" />
        </Tabs>

        <Box py={4}>{renderForm()}</Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCardDialog
