import { Button, ButtonProps } from '@mui/material'
import { MouseEventHandler, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { CardQrCode } from '@/components'

interface PrintButtonProps extends ButtonProps {
  cardId: string
}

const PrintButton = ({ cardId, ...props }: PrintButtonProps) => {
  const cardQrCodeRef = useRef<HTMLDivElement>(null)

  const handlePrint = useReactToPrint({
    content: () => cardQrCodeRef.current,
  })

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    handlePrint()
  }

  return (
    <>
      <Button variant="contained" size="small" onClick={handleClick} {...props}>
        Print
      </Button>

      <CardQrCode ref={cardQrCodeRef} code={`${window.location.origin}/cards/${cardId}`} />
    </>
  )
}

export default PrintButton
