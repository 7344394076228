import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export interface ConfirmationDialogRef {
  show: (data: Data) => Promise<boolean>
}

interface Data {
  title: string
  description?: string
}

const ConfirmationDialog = forwardRef<ConfirmationDialogRef>((_, ref) => {
  const [data, setData] = useState<Data | null>(null)

  const confirmResolver = useRef<{
    resolve: (confirmed: boolean) => void
  }>({
    resolve: () => {},
  })

  const handleShow = async (data: Data) => {
    setData(data)

    const confirmed = await new Promise<boolean>((resolve) => (confirmResolver.current.resolve = resolve))

    return confirmed
  }

  const handleConfirm = () => {
    confirmResolver.current.resolve(true)

    setData(null)
  }

  const handleCancel = () => {
    confirmResolver.current.resolve(false)

    setData(null)
  }

  useImperativeHandle(
    ref,
    () => {
      return { show: handleShow }
    },
    [],
  )

  return (
    <Dialog fullWidth maxWidth="xs" open={!!data} onClose={handleCancel}>
      <DialogTitle>{data?.title}</DialogTitle>

      {!!data?.description && (
        <DialogContent>
          <DialogContentText>{data.description}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>

        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
})

export default ConfirmationDialog
