import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import { LoyaltyOrganizationModel, LoyaltyVenueModel } from '@/types/api'
import { api } from '@/utilities/api'

interface OrganizationsState {
  organizations: LoyaltyOrganizationModel[]
}

interface Venue extends LoyaltyVenueModel {
  organizationId: string
}

const initialState: OrganizationsState = {
  organizations: [],
}

export const organizations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setOrganizations(state, organizations: LoyaltyOrganizationModel[]) {
      return { ...state, organizations }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getOrganizations() {
      const res: AxiosResponse<LoyaltyOrganizationModel[]> = await api.get('/organizations')

      dispatch.organizations.setOrganizations(res.data)
    },
  }),
  selectors: (slice) => ({
    organizationsObj() {
      return slice(({ organizations }) =>
        organizations.reduce<{ [id: string]: LoyaltyOrganizationModel }>((obj, organization) => {
          obj[organization.id!] = organization

          return obj
        }, {}),
      )
    },
    venuesObj() {
      return slice(({ organizations }) => {
        const venues = organizations
          .map((organization) => organization.venues?.map((venue) => ({ ...venue, organizationId: organization.id })))
          .flat() as Venue[]

        return venues.reduce<{ [id: string]: Venue }>((obj, venue) => {
          obj[venue.id!] = venue

          return obj
        }, {})
      })
    },
  }),
})
