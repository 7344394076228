import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import {
  LoyaltyAdminContract,
  LoyaltyAdminUpdateContract,
  LoyaltyAuthenticationContract,
  RegisterLoyaltyAdminContract,
} from '@/types/api'
import { api } from '@/utilities/api'

interface AdminsState {
  admins: LoyaltyAdminContract[]
}

const initialState: AdminsState = {
  admins: [],
}

export const admins = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAdmins(state, admins: LoyaltyAdminContract[]) {
      return { ...state, admins }
    },
    addAdmin(state, admin: LoyaltyAdminContract) {
      return { ...state, admins: [...state.admins, admin] }
    },
    updateAdmin(state, { adminId, data }: { adminId: string; data: LoyaltyAdminContract }) {
      return {
        ...state,
        admins: state.admins.map((admin) => (admin.id === adminId ? { ...admin, ...data } : admin)),
      }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getAdmins() {
      const res: AxiosResponse<LoyaltyAdminContract[]> = await api.get('/admins')

      dispatch.admins.setAdmins(res.data)
    },
    async registerAdmin(payload: RegisterLoyaltyAdminContract) {
      const res: AxiosResponse<LoyaltyAuthenticationContract> = await api.post('/admins/register', payload)

      const data: LoyaltyAdminContract = {
        companyCode: res.data.companyCode,
        companyName: res.data.companyName,
        email: res.data.email,
        id: res.data.id,
        images: res.data.images,
        restaurantIds: res.data.restaurants?.map((restaurant) => restaurant.id!),
        role: res.data.role,
        customizationId: res.data.customizationId,
      }

      dispatch.admins.addAdmin(data)
    },
    async updateAdminRestaurants({ adminId, payload }: { adminId: string; payload: LoyaltyAdminUpdateContract }) {
      await api.post(`/admins/${adminId}/update`, payload)

      dispatch.admins.updateAdmin({ adminId, data: payload })
    },
  }),
  selectors: (slice) => ({
    adminsObj() {
      return slice(({ admins }) =>
        admins.reduce<{ [id: string]: LoyaltyAdminContract }>((obj, admin) => {
          obj[admin.id!] = admin

          return obj
        }, {}),
      )
    },
  }),
})
