import { useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import styled, { AnyStyledComponent } from 'styled-components'

import { useWindowSizes } from '@/utilities/hooks'
import { RootState } from '@/utilities/store'
import Sidebar, { Sizes } from '@/components/Sidebar'
import { WindowSizes } from '@/utilities/functions'
import Header, { HEADER_HEIGHT } from '@/components/Header'

const TRANSITION_TIME = 500 // ms

interface ContentContainerProps {
  menuOpen: boolean
  isUser?: boolean
}

interface SidebarContainerProps {
  sizes: WindowSizes
}

const ContentContainer = styled(Box as AnyStyledComponent)<ContentContainerProps>`
  ${({ isUser, menuOpen }) =>
    !!isUser &&
    `
    padding-left: ${menuOpen ? Sizes.FULL + 1 : Sizes.COMPACT + 1}px;
    transition: padding-left ${TRANSITION_TIME}ms;
  `}
`

const SidebarContainer = styled.div<SidebarContainerProps>`
  overflow-y: auto;
  height: ${({ sizes }) => sizes.height - HEADER_HEIGHT}px;
  border-right: 1px solid #bfbfbf;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  background-color: #ffffff;
`

const PageLayout = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const user = useSelector((state: RootState) => state.authentication.user)

  const sizes = useWindowSizes()

  const location = useLocation()

  const toggleMenuOpen = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen)
  }

  if (user && location.pathname === '/') {
    return <Navigate to="/cards" replace />
  }

  return (
    <>
      <Header menuOpen={menuOpen} onMenuClick={toggleMenuOpen} />

      {!!user && (
        <SidebarContainer sizes={sizes}>
          <Sidebar compact={!menuOpen} transition={TRANSITION_TIME} />
        </SidebarContainer>
      )}

      <ContentContainer isUser={!!user} menuOpen={menuOpen} py={3} pr={3}>
        <Box pl={3}>
          <Outlet />
        </Box>
      </ContentContainer>
    </>
  )
}

export default PageLayout
