import { Paper, TableContainer, Typography } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'

import { DataGridTable } from '@/components'
import { LoyaltyAdminContract } from '@/types/api'
import { getColumnWidth, getStringFilterOperators, isSelection } from '@/utilities/functions'

interface UsersListProps {
  users?: LoyaltyAdminContract[]
  onItemClick?: (userId: string) => void
}

interface RowData extends LoyaltyAdminContract {
  index: number
}

const UsersList = ({ users = [], onItemClick }: UsersListProps) => {
  const [gridWidth, setGridWidth] = useState<number>(0)

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'index',
        headerName: '#',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 6),
        minWidth: 74,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 43),
        sortable: false,
        filterOperators: getStringFilterOperators(['contains']),
      },
      {
        field: 'companyName',
        headerName: 'Company name',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'companyCode',
        headerName: 'Company code',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'role',
        headerName: 'Role',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
    ],
    [gridWidth],
  )

  const rows = useMemo(
    (): RowData[] =>
      users.map((user, index) => ({
        ...user,
        index: index + 1,
      })),
    [users],
  )

  const handleRowClick = (details: GridRowParams<RowData>) => {
    if (isSelection()) {
      return
    }

    onItemClick?.(details.row.id!)
  }

  if (!users.length) {
    return <Typography>No users.</Typography>
  }

  return (
    <TableContainer component={Paper}>
      <DataGridTable
        rows={rows}
        columns={columns}
        disableColumnSelector
        onRowClick={handleRowClick}
        onTableResize={setGridWidth}
      />
    </TableContainer>
  )
}

export default UsersList
