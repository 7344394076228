import { Box, Dialog, DialogContent, DialogContentText } from '@mui/material'

import Spinner from '@/components/Spinner'

interface LoadingDialogProps {
  open?: boolean
  msg?: string
  onClose?: (open: boolean) => void
}

const LoadingDialog = ({ open = false, msg, onClose }: LoadingDialogProps) => {
  const handleClose = () => {
    onClose?.(false)
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogContent>
        <Box display="flex" justifyContent="center" pb={2.5}>
          <Spinner />
        </Box>

        <DialogContentText textAlign="center">{msg}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog
