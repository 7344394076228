import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import {
  CreateAnonymousLoyaltyCardBulkContract,
  CreateLoyaltyCardBulkContract,
  LoyaltyAuthenticationContract,
  LoyaltyCardContract,
  LoyaltyCardDetailsContract,
  UpdateLoyaltyCardBulkContract,
  UpdateLoyaltyDetailsContract,
} from '@/types/api'
import { api } from '@/utilities/api'

interface CardsState {
  cards: LoyaltyCardContract[]
}

const initialState: CardsState = {
  cards: [],
}

export const cards = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCards(state, cards: LoyaltyCardContract[]) {
      return { ...state, cards }
    },
    updateCards(state, updatedCard: LoyaltyCardContract) {
      return {
        ...state,
        cards: state.cards.map((card) => (card.loyaltyCardId === updatedCard.loyaltyCardId ? updatedCard : card)),
      }
    },
    addCards(state, cards: LoyaltyCardContract[]) {
      return { ...state, cards: [...state.cards, ...cards] }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getCards() {
      const res: AxiosResponse<LoyaltyAuthenticationContract> = await api.get('/profile')

      const cards = res.data.loyaltyCards ?? []

      dispatch.cards.setCards(cards)

      return cards
    },
    async getCard(cardId: string) {
      const res: AxiosResponse<LoyaltyCardDetailsContract> = await api.get(`/card/${cardId}`)

      return res.data
    },
    async createPersonalCards(payload: CreateLoyaltyCardBulkContract) {
      const res: AxiosResponse<LoyaltyCardContract[]> = await api.post('/card/bulk', payload)

      dispatch.cards.addCards(res.data)
    },
    async createAnonymousCards(payload: CreateAnonymousLoyaltyCardBulkContract) {
      const res: AxiosResponse<LoyaltyCardContract[]> = await api.post('/card/anonymous/bulk', payload)

      dispatch.cards.addCards(res.data)
    },
    async updateCard({ id, payload }: { id: string; payload: UpdateLoyaltyDetailsContract }) {
      const res: AxiosResponse<LoyaltyCardContract> = await api.put(`/card/${id}/update`, payload)

      dispatch.cards.updateCards(res.data)
    },
    async updateCardsValidity(payload: UpdateLoyaltyCardBulkContract) {
      await api.put('/card/bulk/update', payload)
    },
    async toggleCard(id: string) {
      const res: AxiosResponse<LoyaltyCardContract> = await api.post(`/${id}/toggle`)

      dispatch.cards.updateCards(res.data)
    },
  }),
  selectors: (slice) => ({
    cardsObj() {
      return slice(({ cards }) =>
        cards.reduce<{ [id: string]: LoyaltyCardContract }>((obj, card) => {
          obj[card.loyaltyCardId!] = card

          return obj
        }, {}),
      )
    },
  }),
})
