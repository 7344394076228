import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { LoyaltyAdminUpdateContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { RootState, store } from '@/utilities/store'

const INITIAL_UPDATE_USER_FORM_VALUES: UpdateUserFormValues = {
  restaurantIds: [],
  customizationId: '',
  maximumDiscountAmount: '',
  isDisabled: false,
}

const updateUserSchema: yup.ObjectSchema<UpdateUserFormValues> = yup
  .object({
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
    customizationId: yup.string().label('Customization'),
    maximumDiscountAmount: yup.string().label('Credit amount'),
    isDisabled: yup.boolean().label('Disable user'),
  })
  .required()

interface UpdateUserDialogProps {
  open?: boolean
  selectedUserId?: string
  loading?: boolean
  onUpdate?: (data: {
    userId: string
    data: LoyaltyAdminUpdateContract
  }) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
  onChangePasswordClick?: () => void
}

interface UpdateUserFormValues extends Omit<LoyaltyAdminUpdateContract, 'maximumDiscountAmount'> {
  restaurantIds?: string[]
  customizationId?: string
  maximumDiscountAmount?: string
}

interface UpdateUserFormData extends FormInputData {
  name: keyof UpdateUserFormValues
}

const UpdateUserDialog = ({
  open,
  selectedUserId,
  loading,
  onUpdate,
  onClose,
  onChangePasswordClick,
}: UpdateUserDialogProps) => {
  const restaurants = useSelector((state: RootState) => state.authentication.user?.restaurants ?? [])
  const customizations = useSelector((state: RootState) => state.customizations.customizations)

  const usersObj = useSelector(store.select.users.usersObj)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<UpdateUserFormValues>({
    resolver: yupResolver(updateUserSchema),
  })

  const user = useMemo(() => {
    if (!selectedUserId) {
      return null
    }

    return usersObj[selectedUserId]
  }, [selectedUserId, usersObj])

  const updateUserFormData = useMemo(
    (): UpdateUserFormData[] => [
      {
        type: 'switch',
        name: 'isDisabled',
        label: 'Disable user',
      },
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'restaurantIds',
        label: 'Restaurants',
        items: restaurants.map((restaurant) => ({ value: restaurant.id!, label: restaurant.title ?? '' })),
      },
      {
        type: 'select',
        name: 'customizationId',
        label: 'Customization',
        items: customizations.map((customization) => ({ value: customization.id!, label: customization.name ?? '' })),
        disabled: true,
      },
      {
        type: 'text',
        name: 'maximumDiscountAmount',
        label: 'Credit amount',
        inputType: 'number',
      },
    ],
    [customizations, restaurants],
  )

  const onSubmit: SubmitHandler<UpdateUserFormValues> = async (updateUserFormData) => {
    if (!user) {
      return
    }

    const formData: UpdateUserFormValues = { ...updateUserFormData }
    delete formData.customizationId

    const mappedData = Object.entries(formData).reduce<{ [key: string]: any }>((obj, [key, value]) => {
      switch (key) {
        case 'maximumDiscountAmount':
          value = value ? Number(value) : null
          break
        default:
          break
      }

      obj[key] = value

      return obj
    }, {}) as LoyaltyAdminUpdateContract

    const res = await onUpdate?.({ userId: user.id!, data: mappedData })

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open || !user) {
      return
    }

    reset({
      ...INITIAL_UPDATE_USER_FORM_VALUES,
      restaurantIds: user.restaurantIds ?? INITIAL_UPDATE_USER_FORM_VALUES.restaurantIds,
      customizationId: user.customizationId ?? INITIAL_UPDATE_USER_FORM_VALUES.customizationId,
      maximumDiscountAmount: user.maximumDiscountAmount
        ? String(user.maximumDiscountAmount)
        : INITIAL_UPDATE_USER_FORM_VALUES.maximumDiscountAmount,
      isDisabled: !!user.isDisabled,
    })
  }, [open, reset, user])

  return (
    <Dialog fullWidth open={!!open} onClose={onClose}>
      <DialogTitle>Update user - {user?.email}</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={updateUserFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Box display="flex" columnGap={1} flex={1}>
          <Button type="button" onClick={onChangePasswordClick}>
            Change password
          </Button>

          <Box display="flex" columnGap={1} flex={1} justifyContent="end">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>

            <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateUserDialog
