import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface DisabledUserDialogProps {
  open?: boolean
  onClose?: (open: boolean) => void
}

const DisabledUserDialog = ({ open = false, onClose }: DisabledUserDialogProps) => {
  const handleClose = () => {
    onClose?.(false)
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle>Disabled</DialogTitle>

      <DialogContent>
        <DialogContentText>Loyalty cards are disabled.</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Understood</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisabledUserDialog
