import axios, { AxiosError } from 'axios'

import { store } from '@/utilities/store'
import { ErrorContract } from '@/types/api'
import { logout } from '@/utilities/functions'

const api = axios.create({
  baseURL: process.env.VITE_APP_API_URL,
})

api.interceptors.request.use((config) => {
  const token = store.getState().authentication.user?.token
  if (token) {
    config.headers.Authorization = token
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError<ErrorContract>) => {
    if (error.response?.data.errorCode === 401) {
      logout()
    }

    return Promise.reject(error)
  },
)

export { api }
