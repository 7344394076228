import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { LoyaltyAdminUpdatePasswordContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { store } from '@/utilities/store'

const INITIAL_CHANGE_USER_PASSWORD_FORM_VALUES: LoyaltyAdminUpdatePasswordContract = {
  password: '',
  confirmPassword: '',
}

const CHANGE_USER_PASSWORD_FORM_DATA: ChangeUserPasswordFormData[] = [
  {
    type: 'text',
    name: 'password',
    label: 'Password',
    inputType: 'password',
  },
  {
    type: 'text',
    name: 'confirmPassword',
    label: 'Confirm password',
    inputType: 'password',
  },
]

const changeUserPasswordSchema: yup.ObjectSchema<LoyaltyAdminUpdatePasswordContract> = yup
  .object({
    password: yup.string().required().label('Password'),
    confirmPassword: yup.string().required().label('Confirm password'),
  })
  .required()

interface ChangeUserPasswordDialogProps {
  open?: boolean
  selectedUserId?: string
  loading?: boolean
  onPasswordChange?: (data: {
    userId: string
    data: LoyaltyAdminUpdatePasswordContract
  }) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

interface ChangeUserPasswordFormData extends FormInputData {
  name: keyof LoyaltyAdminUpdatePasswordContract
}

const ChangeUserPasswordDialog = ({
  open,
  selectedUserId,
  loading,
  onPasswordChange,
  onClose,
}: ChangeUserPasswordDialogProps) => {
  const usersObj = useSelector(store.select.users.usersObj)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<LoyaltyAdminUpdatePasswordContract>({
    resolver: yupResolver(changeUserPasswordSchema),
  })

  const user = useMemo(() => {
    if (!selectedUserId) {
      return
    }

    return usersObj[selectedUserId]
  }, [selectedUserId, usersObj])

  const onSubmit: SubmitHandler<LoyaltyAdminUpdatePasswordContract> = async (changeUserPasswordFormData) => {
    if (!user) {
      return
    }

    const res = await onPasswordChange?.({ userId: user.id!, data: changeUserPasswordFormData })

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    reset(INITIAL_CHANGE_USER_PASSWORD_FORM_VALUES)
  }, [open, reset])

  return (
    <Dialog fullWidth open={!!open} onClose={onClose}>
      <DialogTitle>Change user password - {user?.email}</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={CHANGE_USER_PASSWORD_FORM_DATA} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeUserPasswordDialog
