import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { createSelector } from 'reselect'

import { RootModel } from '@/models'
import { CredentialsContract, LoyaltyAdminRole, LoyaltyAuthenticationContract } from '@/types/api'
import { api } from '@/utilities/api'
import { RootState } from '@/utilities/store'

interface AuthenticationState {
  user: LoyaltyAuthenticationContract | null
}

const initialState: AuthenticationState = {
  user: null,
}

export const authentication = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, user: LoyaltyAuthenticationContract) {
      return { ...state, user }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async login(payload: CredentialsContract) {
      const { data }: AxiosResponse<LoyaltyAuthenticationContract> = await api.post('/authenticate', payload)

      dispatch.authentication.setUser(data)

      return data
    },
    async getUser() {
      const { data }: AxiosResponse<LoyaltyAuthenticationContract> = await api.get('/profile')

      dispatch.authentication.setUser(data)

      return data
    },
  }),
})

export const selectIsUserRole = createSelector(
  (rootState: RootState) => rootState.authentication,
  (state) => state.user?.role === LoyaltyAdminRole.User,
)
