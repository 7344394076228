import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RegisterLoyaltyUserContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { RootState } from '@/utilities/store'

const INITIAL_CREATE_USER_FORM_VALUES: CreateUserFormValues = {
  email: '',
  password: '',
  restaurantIds: [],
  companyName: '',
  companyCode: '',
  customizationId: '',
  maximumDiscountAmount: '',
}

const createUserSchema: yup.ObjectSchema<CreateUserFormValues> = yup
  .object({
    email: yup.string().email().required().label('Email'),
    password: yup.string().required().label('Password'),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
    companyName: yup.string().required().label('Company name'),
    companyCode: yup.string().required().label('Company code'),
    customizationId: yup.string().required().label('Customization'),
    maximumDiscountAmount: yup.string().label('Credit amount'),
  })
  .required()

interface CreateUserDialogProps {
  open?: boolean
  loading?: boolean
  onCreate?: (data: RegisterLoyaltyUserContract) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

interface CreateUserFormValues extends Omit<RegisterLoyaltyUserContract, 'managingAdminId' | 'maximumDiscountAmount'> {
  restaurantIds?: string[]
  maximumDiscountAmount?: string
}

interface CreateUserFormData extends FormInputData {
  name: keyof CreateUserFormValues
}

const CreateUserDialog = ({ open, loading, onCreate, onClose }: CreateUserDialogProps) => {
  const restaurants = useSelector((state: RootState) => state.authentication.user?.restaurants ?? [])
  const user = useSelector((state: RootState) => state.authentication.user)
  const customizations = useSelector((state: RootState) => state.customizations.customizations)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<CreateUserFormValues>({
    resolver: yupResolver(createUserSchema),
  })

  const createUserFormData = useMemo(
    (): CreateUserFormData[] => [
      {
        type: 'text',
        name: 'email',
        label: 'Email',
        inputType: 'email',
      },
      {
        type: 'text',
        name: 'password',
        label: 'Password',
        inputType: 'password',
      },
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'restaurantIds',
        label: 'Restaurants',
        items: restaurants.map((restaurant) => ({ value: restaurant.id!, label: restaurant.title ?? '' })),
      },
      {
        type: 'select',
        name: 'customizationId',
        label: 'Customization',
        items: customizations.map((customization) => ({ value: customization.id!, label: customization.name ?? '' })),
      },
      {
        type: 'text',
        name: 'companyName',
        label: 'Company name',
      },
      {
        type: 'text',
        name: 'companyCode',
        label: 'Company code',
      },
      {
        type: 'text',
        name: 'maximumDiscountAmount',
        label: 'Credit amount',
        inputType: 'number',
      },
    ],
    [customizations, restaurants],
  )

  const onSubmit: SubmitHandler<CreateUserFormValues> = async (createUserFormData) => {
    if (!user) {
      return
    }

    const mappedData = Object.entries(createUserFormData).reduce<{ [key: string]: any }>((obj, [key, value]) => {
      switch (key) {
        case 'maximumDiscountAmount':
          value = value ? Number(value) : null
          break
        default:
          break
      }

      obj[key] = value

      return obj
    }, {}) as RegisterLoyaltyUserContract

    const res = await onCreate?.({ ...mappedData, managingAdminId: user.id! })

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    reset(INITIAL_CREATE_USER_FORM_VALUES)
  }, [open, reset])

  return (
    <Dialog fullWidth open={!!open} onClose={onClose}>
      <DialogTitle>Create user</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={createUserFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUserDialog
