import { useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'

import { ErrorContract, LoyaltyCardDetailsContract } from '@/types/api'
import { Dispatch } from '@/utilities/store'
import EmailTemplate from './EmailTemplate'
import { Spinner } from '@/components'
import { useWindowSizes } from '@/utilities/hooks'
import { loadFont } from '@/utilities/functions'

const Card = () => {
  const [card, setCard] = useState<LoyaltyCardDetailsContract>()
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedFontFamily, setSelectedFontFamily] = useState<string>()

  const params = useParams<{ cardId: string }>()

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const init = async (cardId: string) => {
    setLoading(true)

    const card = await getCard(cardId)
    if (card) {
      setCard(card)

      if (card.customizationProfile?.font) {
        const fontFamily = await loadFont(card.customizationProfile.font)

        if (fontFamily) {
          setSelectedFontFamily(fontFamily)
        }
      }
    }

    setLoading(false)
  }

  const getCard = async (cardId: string) => {
    try {
      return await dispatch.cards.getCard(cardId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  useEffect(() => {
    if (!params.cardId) {
      return
    }

    init(params.cardId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.cardId])

  if (loading) {
    return (
      <Box height={sizes.height} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  return <EmailTemplate card={card} fontFamily={selectedFontFamily} />
}

export default Card
