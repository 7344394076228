import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'

import Login from '@/pages/Login'
import Cards from '@/pages/Cards'
import Card from '@/pages/Card'
import Customizations from '@/pages/Customizations'
import Users from '@/pages/Users'
import { PageLayout, ProtectedRoutes, Spinner } from '@/components'
import { useWindowSizes } from '@/utilities/hooks'
import { Dispatch, RootState } from '@/utilities/store'
import { setSentryUser } from '@/utilities/functions'

const router = createBrowserRouter([
  {
    element: <PageLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: '/cards',
            element: <Cards />,
          },
          {
            path: '/customizations',
            element: <Customizations />,
          },
          {
            path: '/users',
            element: <Users />,
          },
        ],
      },
    ],
  },
  {
    path: '/cards/:cardId',
    element: <Card />,
  },
])

const Router = () => {
  const [loading, setLoading] = useState<boolean>(true)

  const user = useSelector((state: RootState) => state.authentication.user)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const getUser = async () => {
    try {
      const authUser = await dispatch.authentication.getUser()

      setSentryUser({ id: authUser.id })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      setLoading(false)
      return
    }

    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box height={sizes.height} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  return <RouterProvider router={router} />
}

export default Router
