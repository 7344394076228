import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid'
import { UseFormSetError } from 'react-hook-form'
import WebFont from 'webfontloader'
import * as Sentry from '@sentry/react'

import { ValidationErrorContract } from '@/types/api'
import { store } from '@/utilities/store'
import { CustomFontFamilies, CustomFontFamilyKeys, FontEvent } from '@/types/fonts'
import { PLACEHOLDERS } from './constants'
import { PlaceholderKeys } from '@/types'
import fonts from '@/styles/fonts.css'

export interface WindowSizes {
  width: number
  height: number
}

export type FilterOperators = 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'isEmpty' | 'isNotEmpty' | 'isAnyOf'

export const logout = () => {
  store.dispatch.authentication.resetState()
  store.dispatch.cards.resetState()
  store.dispatch.customizations.resetState()
  store.dispatch.images.resetState()
  store.dispatch.users.resetState()
  store.dispatch.admins.resetState()
  store.dispatch.organizations.resetState()
  store.dispatch.fonts.resetState()

  setSentryUser(null)
}

export const getWindowSizes = (): WindowSizes => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const isSelection = () => {
  return !!window.getSelection()?.toString()
}

export const getColumnWidth = (fullWidth: number, percentage: number) => {
  return Math.floor((fullWidth * percentage) / 100)
}

export const getStringFilterOperators = (operators: FilterOperators[]) => {
  const stringOperators = getGridStringOperators()

  const stringOperatorsObj = stringOperators.reduce<{
    [value: string]: GridFilterOperator<any, string | number | null, any>
  }>((obj, stringOperator) => {
    obj[stringOperator.value] = stringOperator
    return obj
  }, {})

  return operators.map((operator) => stringOperatorsObj[operator])
}

export const setFormErrors = (errors?: ValidationErrorContract[], setError?: UseFormSetError<any>) => {
  errors?.forEach((error) => {
    setError?.(error.fieldName, {
      type: 'api',
      message: error.errorMessage,
    })
  })
}

export const loadCustomFont = (familyName: CustomFontFamilyKeys) => {
  return new Promise<FontEvent>((resolve, reject) => {
    WebFont.load({
      custom: {
        families: [familyName],
        urls: [fonts],
      },
      fontactive: (familyName, fvd) => {
        resolve({ familyName, fvd })
      },
      fontinactive: (familyName, fvd) => {
        reject({ familyName, fvd })
      },
    })
  })
}

export const loadGoogleFont = (familyName: string) => {
  return new Promise<FontEvent>((resolve, reject) => {
    WebFont.load({
      google: {
        families: [familyName],
      },
      fontactive: (familyName, fvd) => {
        resolve({ familyName, fvd })
      },
      fontinactive: (familyName, fvd) => {
        reject({ familyName, fvd })
      },
    })
  })
}

export const loadFont = async (familyName: string) => {
  try {
    if (Object.keys(CustomFontFamilies).find((key) => key === familyName)) {
      await loadCustomFont(familyName as CustomFontFamilyKeys)

      return familyName
    }

    await loadGoogleFont(familyName)

    return familyName
  } catch (error) {
    alert(`Font "${familyName}" can't be loaded.`)
  }
}

export const replacePlaceholdersWithText = (
  textWithPlaceholders: string,
  placeholdersToReplace: { [key in PlaceholderKeys]?: string }[],
) => {
  placeholdersToReplace.forEach((placeholderToReplace) =>
    Object.entries(placeholderToReplace).forEach(([key, value]) => {
      const placeholder = PLACEHOLDERS[key as PlaceholderKeys]
      if (!placeholder) {
        return
      }

      const re = new RegExp(`${placeholder}`, 'g')

      textWithPlaceholders = textWithPlaceholders.replace(re, value)
    }),
  )

  return textWithPlaceholders
}

export const setSentryUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}
