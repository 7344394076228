import { useEffect, useState } from 'react'

import { WindowSizes, getWindowSizes } from '@/utilities/functions'

export const useWindowSizes = () => {
  const [sizes, setSizes] = useState<WindowSizes>(getWindowSizes())

  const handleResize = () => {
    setSizes(getWindowSizes())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return sizes
}
