import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RegisterLoyaltyAdminContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { RootState, store } from '@/utilities/store'

const INITIAL_CREATE_ADMIN_FORM_VALUES: CreateAdminFormValues = {
  email: '',
  password: '',
  organizationIds: [],
  restaurantIds: [],
  companyName: '',
  companyCode: '',
}

const createAdminSchema: yup.ObjectSchema<CreateAdminFormValues> = yup
  .object({
    email: yup.string().email().required().label('Email'),
    password: yup.string().required().label('Password'),
    organizationIds: yup.array().of(yup.string().required()).label('Organizations'),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
    companyName: yup.string().label('Company name'),
    companyCode: yup.string().label('Company code'),
  })
  .required()

interface CreateAdminDialogProps {
  open?: boolean
  loading?: boolean
  onCreate?: (data: RegisterLoyaltyAdminContract) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

interface CreateAdminFormValues extends Omit<RegisterLoyaltyAdminContract, 'maximumDiscountAmount'> {
  organizationIds?: string[]
  restaurantIds?: string[]
  companyName?: string
  companyCode?: string
}

interface CreateAdminFormData extends FormInputData {
  name: keyof CreateAdminFormValues
}

const CreateAdminDialog = ({ open, loading, onCreate, onClose }: CreateAdminDialogProps) => {
  const organizations = useSelector((state: RootState) => state.organizations.organizations)

  const venuesObj = useSelector(store.select.organizations.venuesObj)
  const organizationsObj = useSelector(store.select.organizations.organizationsObj)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    getValues,
  } = useForm<CreateAdminFormValues>({
    resolver: yupResolver(createAdminSchema),
  })

  const organizationIds = watch('organizationIds', INITIAL_CREATE_ADMIN_FORM_VALUES.organizationIds)

  const organizationIdsObj = useMemo(
    () =>
      organizationIds?.reduce<{ [id: string]: string }>((obj, organizationId) => {
        obj[organizationId] = organizationId
        return obj
      }, {}) ?? {},
    [organizationIds],
  )

  const createAdminFormData = useMemo(
    (): CreateAdminFormData[] => [
      {
        type: 'text',
        name: 'email',
        label: 'Email',
        inputType: 'email',
      },
      {
        type: 'text',
        name: 'password',
        label: 'Password',
        inputType: 'password',
      },
      {
        type: 'multiple-autocomplete',
        name: 'organizationIds',
        label: 'Organizations',
        items: organizations.map((organization) => ({ value: organization.id!, label: organization.title ?? '' })),
      },
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'restaurantIds',
        label: 'Restaurants',
        items: Object.values(venuesObj)
          .filter((venue) => !!organizationIdsObj[venue.organizationId])
          .map((venue) => ({
            value: venue.id!,
            label: venue.title ?? '',
            groupLabel: organizationsObj[venue.organizationId!].title ?? '',
          })),
        disabled: !organizationIds?.length,
      },
      {
        type: 'text',
        name: 'companyName',
        label: 'Company name',
      },
      {
        type: 'text',
        name: 'companyCode',
        label: 'Company code',
      },
    ],
    [organizationIds?.length, organizationIdsObj, organizations, organizationsObj, venuesObj],
  )

  const onSubmit: SubmitHandler<CreateAdminFormValues> = async (createAdminFormData) => {
    const formData: CreateAdminFormValues = { ...createAdminFormData }
    delete formData.organizationIds

    const mappedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value || null]),
    ) as RegisterLoyaltyAdminContract

    const res = await onCreate?.(mappedFormData)

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    reset(INITIAL_CREATE_ADMIN_FORM_VALUES)
  }, [open, reset])

  useEffect(() => {
    const restaurantIds = getValues('restaurantIds') ?? []

    const filteredRestaurantIds = restaurantIds.filter(
      (restaurantId) => !!organizationIdsObj[venuesObj[restaurantId].organizationId],
    )

    setValue('restaurantIds', filteredRestaurantIds)
  }, [getValues, organizationIdsObj, setValue, venuesObj])

  return (
    <Dialog fullWidth open={!!open} onClose={onClose}>
      <DialogTitle>Create admin</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={createAdminFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateAdminDialog
