import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import persistPlugin from '@rematch/persist'
import storage from 'redux-persist/lib/storage'
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading'
import selectPlugin from '@rematch/select'

import { models, RootModel } from '@/models'

type FullModel = ExtraModelsFromLoading<RootModel>

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
}

export const store = init<RootModel, FullModel>({
  models,
  plugins: [persistPlugin(persistConfig), loadingPlugin(), selectPlugin()],
})

export type Store = typeof store

export type Dispatch = RematchDispatch<RootModel>

export type RootState = RematchRootState<RootModel, FullModel>
