import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CsvDownloader from 'react-csv-downloader'

import { selectCustomizationsObj } from '@/models/customizations'
import { Dispatch } from '@/utilities/store'
import { dayjs } from '@/utilities/dayjs'
import { useState } from 'react'

const COLUMNS: { id: keyof Data; displayName: string }[] = [
  { id: 'index', displayName: '#' },
  { id: 'email', displayName: 'Email' },
  { id: 'description', displayName: 'Description' },
  { id: 'companyName', displayName: 'Company name' },
  { id: 'companyCode', displayName: 'Company code' },
  { id: 'code', displayName: 'Code' },
  { id: 'credits', displayName: 'Credits' },
  { id: 'isDisabled', displayName: 'On/Off' },
  { id: 'validTo', displayName: 'Valid to' },
  { id: 'customizationName', displayName: 'Customization name' },
  { id: 'cardUrl', displayName: 'Card url' },
]

interface Data {
  index: string
  email: string
  description: string
  companyName: string
  companyCode: string
  code: string
  credits: string
  isDisabled: string
  validTo: string
  customizationName: string
  cardUrl: string
}

const DownloadCSVButton = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const customizationsObj = useSelector(selectCustomizationsObj)

  const dispatch = useDispatch<Dispatch>()

  const getDatas = async () => {
    try {
      setLoading(true)

      const cards = await dispatch.cards.getCards()

      return cards.map((card, index) => ({
        index: String(index + 1),
        email: card.email ?? '',
        description: card.description ?? '',
        companyName: card.companyName ?? '',
        companyCode: card.companyCode ?? '',
        code: card.code ? card.code.substring(1) : '',
        credits: typeof card.credits === 'number' ? String(card.credits) : '',
        isDisabled: typeof card.isDisabled === 'boolean' ? (card.isDisabled ? 'Disabled' : 'Enabled') : '',
        validTo: card.discount?.validTo ? dayjs.utc(card.discount.validTo).format('YYYY-MM-DD HH:mm') : '',
        customizationName: card.customizationId ? customizationsObj[card.customizationId]?.name ?? '' : '',
        cardUrl: card.loyaltyCardId ? `${window.location.origin}/cards/${card.loyaltyCardId}` : '',
      }))
    } catch (error) {
      console.error(error)

      return []
    } finally {
      setLoading(false)
    }
  }

  return (
    <CsvDownloader datas={getDatas} columns={COLUMNS} filename="loyalty-cards" disabled={loading}>
      <Button variant="contained" disabled={loading}>
        Download CSV
      </Button>
    </CsvDownloader>
  )
}

export default DownloadCSVButton
