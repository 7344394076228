import { DataGrid, DataGridProps, ElementSize, GridRowSelectionApi, useGridApiRef } from '@mui/x-data-grid'
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { forwardRef, useImperativeHandle } from 'react'

export interface DataGridTableRef {
  setRowSelectionModel: GridRowSelectionApi['setRowSelectionModel']
  getSelectedRows: GridRowSelectionApi['getSelectedRows']
  getState: () => GridStateCommunity
}

interface DataGridTableProps extends DataGridProps {
  onTableResize?: (width: number) => void
}

const DataGridTable = forwardRef<DataGridTableRef, DataGridTableProps>(({ onTableResize, ...props }, ref) => {
  const apiRef = useGridApiRef()

  const handleResize = (details: ElementSize) => {
    onTableResize?.(details.width)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        setRowSelectionModel: apiRef.current.setRowSelectionModel,
        getSelectedRows: apiRef.current.getSelectedRows,
        getState: () => apiRef.current.state,
      }
    },
    [apiRef],
  )

  return (
    <DataGrid
      apiRef={apiRef}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      autoHeight
      pageSizeOptions={[10, 20, 40]}
      rowSelection={false}
      onResize={handleResize}
      {...props}
    />
  )
})

export default DataGridTable
