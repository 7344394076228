import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import {
  LoyaltyAdminContract,
  LoyaltyAdminUpdateContract,
  LoyaltyAdminUpdatePasswordContract,
  LoyaltyAuthenticationContract,
  RegisterLoyaltyUserContract,
} from '@/types/api'
import { api } from '@/utilities/api'

interface UsersState {
  users: LoyaltyAdminContract[]
}

const initialState: UsersState = {
  users: [],
}

export const users = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUsers(state, users: LoyaltyAdminContract[]) {
      return { ...state, users }
    },
    addUser(state, user: LoyaltyAdminContract) {
      return { ...state, users: [...state.users, user] }
    },
    updateUser(state, { userId, data }: { userId: string; data: LoyaltyAdminContract }) {
      return {
        ...state,
        users: state.users.map((user) => (user.id === userId ? { ...user, ...data } : user)),
      }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getUsers() {
      const res: AxiosResponse<LoyaltyAdminContract[]> = await api.get('/users')

      dispatch.users.setUsers(res.data)
    },
    async registerUser(payload: RegisterLoyaltyUserContract) {
      const res: AxiosResponse<LoyaltyAuthenticationContract> = await api.post('/users/register', payload)

      const data: LoyaltyAdminContract = {
        companyCode: res.data.companyCode,
        companyName: res.data.companyName,
        email: res.data.email,
        id: res.data.id,
        images: res.data.images,
        restaurantIds: res.data.restaurants?.map((restaurant) => restaurant.id!),
        role: res.data.role,
        customizationId: res.data.customizationId,
      }

      dispatch.users.addUser(data)
    },
    async updateUserRestaurants({ userId, payload }: { userId: string; payload: LoyaltyAdminUpdateContract }) {
      await api.post(`/admins/${userId}/update`, payload)

      dispatch.users.updateUser({ userId, data: payload })
    },
    async changeUserPassword({ userId, payload }: { userId: string; payload: LoyaltyAdminUpdatePasswordContract }) {
      await api.post(`/admins/${userId}/password`, payload)
    },
  }),
  selectors: (slice) => ({
    usersObj() {
      return slice(({ users }) =>
        users.reduce<{ [id: string]: LoyaltyAdminContract }>((obj, user) => {
          obj[user.id!] = user

          return obj
        }, {}),
      )
    },
  }),
})
