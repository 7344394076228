import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { UpdateLoyaltyCardBulkContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { dayjs } from '@/utilities/dayjs'
import { RootState } from '@/utilities/store'
import { selectIsUserRole } from '@/models/authentication'

const INITIAL_UPDATE_CARDS_VALIDITY_FORM_VALUES: UpdateCardsValidityFormValues = {
  validTo: '',
  customizationId: '',
  totalMonthlyAmount: '',
}

const updateCardsValiditySchema: yup.ObjectSchema<UpdateCardsValidityFormValues> = yup
  .object({
    validTo: yup.string().label('Valid to'),
    customizationId: yup.string().label('Customization'),
    totalMonthlyAmount: yup.string().label('Total monthly amount'),
  })
  .required()

interface UpdateCardsValidityDialogProps {
  open?: boolean
  loading?: boolean
  onUpdate?: (
    data: Omit<UpdateLoyaltyCardBulkContract, 'ids'>,
  ) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

interface UpdateCardsValidityFormValues {
  validTo?: string
  customizationId?: string
  totalMonthlyAmount?: string
}

interface UpdateCardsValidityFormData extends FormInputData {
  name: keyof UpdateCardsValidityFormValues
}

const UpdateCardsValidityDialog = ({ open, loading, onUpdate, onClose }: UpdateCardsValidityDialogProps) => {
  const customizations = useSelector((state: RootState) => state.customizations.customizations)

  const isUserRole = useSelector(selectIsUserRole)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<UpdateCardsValidityFormValues>({
    resolver: yupResolver(updateCardsValiditySchema),
  })

  const customizationItems = useMemo(() => {
    if (isUserRole) {
      return []
    }

    return customizations.map((customization) => ({ value: customization.id!, label: customization.name ?? '' }))
  }, [customizations, isUserRole])

  const updateCardsValidityFormData = useMemo(() => {
    const data: UpdateCardsValidityFormData[] = [
      {
        type: 'text',
        name: 'validTo',
        label: 'Valid to',
        inputType: 'datetime-local',
        labelShrink: true,
      },
      {
        type: 'select',
        name: 'customizationId',
        label: 'Customization',
        items: customizationItems,
      },
      {
        type: 'text',
        name: 'totalMonthlyAmount',
        label: 'Total monthly amount',
        inputType: 'number',
        onlyIntegers: true,
      },
    ]

    const filteredData = data.filter((d) => {
      if (!isUserRole) {
        return true
      }

      return d.name !== 'customizationId'
    })

    return filteredData
  }, [customizationItems, isUserRole])

  const onSubmit: SubmitHandler<UpdateCardsValidityFormValues> = async (updateCardsValidityFormData) => {
    const mappedData = Object.entries(updateCardsValidityFormData).reduce<Record<string, any>>((obj, [key, value]) => {
      if (!value) {
        obj[key] = null
        return obj
      }

      if (key === 'validTo') {
        obj[key] = dayjs.utc(value).unix()
        return obj
      }

      if (key === 'totalMonthlyAmount') {
        obj[key] = Number(value)
        return obj
      }

      obj[key] = value

      return obj
    }, {})

    const res = await onUpdate?.(mappedData)

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    reset(INITIAL_UPDATE_CARDS_VALIDITY_FORM_VALUES)
  }, [open, reset])

  return (
    <Dialog fullWidth open={!!open} onClose={onClose}>
      <DialogTitle>Update cards validity</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={updateCardsValidityFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateCardsValidityDialog
