import { useMemo, useState } from 'react'
import { Paper, TableContainer, Typography } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'

import { LoyaltyCustomizationContract } from '@/types/api'
import { getColumnWidth, getStringFilterOperators, isSelection } from '@/utilities/functions'
import { DataGridTable } from '@/components'

interface CustomizationsListProps {
  customizations?: LoyaltyCustomizationContract[]
  onItemClick?: (customizationId: string) => void
}

interface RowData extends LoyaltyCustomizationContract {
  index: number
}

const CustomizationsList = ({ customizations = [], onItemClick }: CustomizationsListProps) => {
  const [gridWidth, setGridWidth] = useState<number>(0)

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'index',
        headerName: '#',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 6),
        minWidth: 74,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 26),
        sortable: false,
        filterOperators: getStringFilterOperators(['contains']),
      },
      {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'organizationName',
        headerName: 'Organization name',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone number',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        field: 'webpage',
        headerName: 'Webpage',
        headerAlign: 'center',
        align: 'center',
        width: getColumnWidth(gridWidth, 17),
        minWidth: 209,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
    ],
    [gridWidth],
  )

  const rows = useMemo(
    (): RowData[] =>
      customizations.map((customization, index) => ({
        ...customization,
        index: index + 1,
      })),
    [customizations],
  )

  const handleRowClick = (details: GridRowParams<RowData>) => {
    if (isSelection()) {
      return
    }

    onItemClick?.(details.row.id!)
  }

  if (!customizations.length) {
    return <Typography>No customizations.</Typography>
  }

  return (
    <TableContainer component={Paper}>
      <DataGridTable
        rows={rows}
        columns={columns}
        disableColumnSelector
        onRowClick={handleRowClick}
        onTableResize={setGridWidth}
      />
    </TableContainer>
  )
}

export default CustomizationsList
