import { ChangeEvent, FormEvent, HTMLInputTypeAttribute, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { CredentialsContract, ErrorContract, LoyaltyAdminRole } from '@/types/api'
import { Dispatch, RootState } from '@/utilities/store'
import { setSentryUser } from '@/utilities/functions'

const FORM_DATA: FormData[] = [
  {
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
  },
]

interface FormData {
  name: keyof CredentialsContract
  type: HTMLInputTypeAttribute
  label: ReactNode
}

const Login = () => {
  const [user, setUser] = useState<CredentialsContract>({ email: '', password: '' })

  const loading = useSelector((state: RootState) => state.loading.effects.authentication.login)

  const dispatch = useDispatch<Dispatch>()

  const navigate = useNavigate()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const authUser = await dispatch.authentication.login(user)

      setSentryUser({ id: authUser.id })

      if (authUser.role === LoyaltyAdminRole.Master) {
        navigate('/users', { replace: true })
        return
      }

      navigate('/cards', { replace: true })
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item md={4} xs={12}>
        <Card raised>
          <CardContent component="form" onSubmit={handleSubmit}>
            <Typography variant="h5">Login</Typography>

            <Grid container direction="column" rowSpacing={2} py={4}>
              {FORM_DATA.map((data, index) => (
                <Grid key={index} item xs>
                  <TextField
                    name={data.name}
                    type={data.type}
                    label={data.label}
                    fullWidth
                    required
                    disabled={loading}
                    value={user[data.name]}
                    onChange={handleChange}
                  />
                </Grid>
              ))}
            </Grid>

            <Button type="submit" variant="contained" fullWidth disabled={loading}>
              Login
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Login
