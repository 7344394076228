import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { AnyStyledComponent } from 'styled-components'

import { LoyaltyAdminUpdateContract, ValidationErrorContract } from '@/types/api'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'
import { RootState, store } from '@/utilities/store'

const PaperWithoutScrollbar = styled(Paper as AnyStyledComponent)`
  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const INITIAL_UPDATE_ADMIN_FORM_VALUES: UpdateAdminFormValues = {
  organizationIds: [],
  restaurantIds: [],
}

const updateAdminSchema: yup.ObjectSchema<UpdateAdminFormValues> = yup
  .object({
    organizationIds: yup.array().of(yup.string().required()).label('Organizations'),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
  })
  .required()

interface UpdateAdminDialogProps {
  open?: boolean
  selectedAdminId?: string
  loading?: boolean
  onUpdate?: (data: {
    adminId: string
    data: LoyaltyAdminUpdateContract
  }) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

interface UpdateAdminFormValues extends Omit<LoyaltyAdminUpdateContract, 'maximumDiscountAmount' | 'isDisabled'> {
  organizationIds?: string[]
  restaurantIds?: string[]
}

interface UpdateAdminFormData extends FormInputData {
  name: keyof UpdateAdminFormValues
}

const UpdateAdminDialog = ({ open, selectedAdminId, loading, onUpdate, onClose }: UpdateAdminDialogProps) => {
  const organizations = useSelector((state: RootState) => state.organizations.organizations)

  const venuesObj = useSelector(store.select.organizations.venuesObj)
  const organizationsObj = useSelector(store.select.organizations.organizationsObj)
  const adminsObj = useSelector(store.select.admins.adminsObj)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    getValues,
  } = useForm<UpdateAdminFormValues>({
    resolver: yupResolver(updateAdminSchema),
  })

  const watchOrganizationIds = watch('organizationIds', INITIAL_UPDATE_ADMIN_FORM_VALUES.organizationIds)

  const organizationIdsObj = useMemo(
    () =>
      watchOrganizationIds?.reduce<{ [id: string]: string }>((obj, organizationId) => {
        obj[organizationId] = organizationId
        return obj
      }, {}) ?? {},
    [watchOrganizationIds],
  )

  const admin = useMemo(() => {
    if (!selectedAdminId) {
      return null
    }

    return adminsObj[selectedAdminId]
  }, [adminsObj, selectedAdminId])

  const updateAdminFormData = useMemo(
    (): UpdateAdminFormData[] => [
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'organizationIds',
        label: 'Organizations',
        items: organizations.map((organization) => ({ value: organization.id!, label: organization.title ?? '' })),
      },
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'restaurantIds',
        label: 'Restaurants',
        items: Object.values(venuesObj)
          .filter((venue) => !!organizationIdsObj[venue.organizationId])
          .map((venue) => ({
            value: venue.id!,
            label: venue.title ?? '',
            groupLabel: organizationsObj[venue.organizationId!].title ?? '',
          })),
        disabled: !watchOrganizationIds?.length,
      },
    ],
    [watchOrganizationIds?.length, organizationIdsObj, organizations, organizationsObj, venuesObj],
  )

  const onSubmit: SubmitHandler<UpdateAdminFormValues> = async (updateAdminFormData) => {
    if (!admin) {
      return
    }

    const formData: UpdateAdminFormValues = { ...updateAdminFormData }
    delete formData.organizationIds

    const res = await onUpdate?.({ adminId: admin.id!, data: formData })

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!open || !admin) {
      return
    }

    const organizationIds = Object.keys(
      (admin.restaurantIds ?? []).reduce<{ [organizationId: string]: string }>((obj, restaurantId) => {
        const organizationId = venuesObj[restaurantId].organizationId
        obj[organizationId] = organizationId
        return obj
      }, {}),
    )

    reset({
      ...INITIAL_UPDATE_ADMIN_FORM_VALUES,
      organizationIds,
      restaurantIds: admin.restaurantIds ?? INITIAL_UPDATE_ADMIN_FORM_VALUES.restaurantIds,
    })
  }, [admin, open, reset, venuesObj])

  useEffect(() => {
    const restaurantIds = getValues('restaurantIds') ?? []

    const filteredRestaurantIds = restaurantIds.filter(
      (restaurantId) => !!organizationIdsObj[venuesObj[restaurantId].organizationId],
    )

    setValue('restaurantIds', filteredRestaurantIds)
  }, [getValues, organizationIdsObj, setValue, venuesObj])

  return (
    <Dialog fullWidth open={!!open} PaperComponent={PaperWithoutScrollbar} onClose={onClose}>
      <DialogTitle>Update admin - {admin?.email}</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={updateAdminFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateAdminDialog
