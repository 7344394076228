import { MouseEvent, useState } from 'react'
import { AppBar, Box, Button, Menu, MenuItem, Toolbar } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { RootState } from '@/utilities/store'
import { logout } from '@/utilities/functions'
import BrandLogoIcon from '@/assets/icons/brand-logo.svg'
import { HamburgerMenu } from '@/components'

export const HEADER_HEIGHT = 64

interface HeaderProps {
  menuOpen?: boolean
  onMenuClick?: () => void
}

const BrandLogo = styled.img`
  height: 2rem;
`

const Header = ({ menuOpen, onMenuClick }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const user = useSelector((state: RootState) => state.authentication.user)

  const navigate = useNavigate()

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()

    logout()

    navigate('/', { replace: true })
  }

  return (
    <AppBar position="sticky" color="default" sx={{ height: HEADER_HEIGHT, justifyContent: 'center' }}>
      <Toolbar>
        <Box flexGrow={1} display="flex" alignItems="center" columnGap={2}>
          {!!user && <HamburgerMenu open={menuOpen} onClick={onMenuClick} />}

          <BrandLogo src={BrandLogoIcon} alt="brand-logo" />
        </Box>

        {!!user && (
          <div>
            <Button onClick={handleOpen}>{user.email}</Button>

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
