import { Menu, Close } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'

interface HamburgerMenuProps extends IconButtonProps {
  open?: boolean
}

const HamburgerMenu = ({ open, ...props }: HamburgerMenuProps) => {
  return (
    <IconButton type="button" edge="start" disableRipple {...props}>
      {open ? <Close /> : <Menu />}
    </IconButton>
  )
}

export default HamburgerMenu
