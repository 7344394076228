import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '@/models'
import { api } from '@/utilities/api'
import { LoyaltyFontContract } from '@/types/api'

interface FontsState {
  fonts: LoyaltyFontContract[]
}

const initialState: FontsState = {
  fonts: [],
}

export const fonts = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFonts(state, fonts: LoyaltyFontContract[]) {
      return { ...state, fonts }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getFonts() {
      const res: AxiosResponse<LoyaltyFontContract[]> = await api.get('/fonts')

      dispatch.fonts.setFonts(res.data)
    },
  }),
})
