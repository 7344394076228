import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Button, Snackbar } from '@mui/material'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { Dispatch, RootState, store } from '@/utilities/store'
import {
  CreateAnonymousLoyaltyCardBulkContract,
  CreateLoyaltyCardBulkContract,
  ErrorContract,
  UpdateLoyaltyCardBulkContract,
  UpdateLoyaltyDetailsContract,
} from '@/types/api'
import CardsList, { CardsListRef } from './components/CardsList'
import { useWindowSizes } from '@/utilities/hooks'
import { LoadingDialog, Spinner } from '@/components'
import { HEADER_HEIGHT } from '@/components/Header'
import CreateCardDialog from './components/CreateCardDialog'
import UpdateCardDialog from './components/UpdateCardDialog'
import ConfirmationDialog, { ConfirmationDialogRef } from '@/components/ConfirmationDialog'
import UpdateCardsValidityDialog from './components/UpdateCardsValidityDialog'
import DownloadCSVButton from './components/DownloadCSVButton'

const Cards = () => {
  const [createCardDialogOpen, setCreateCardDialogOpen] = useState<boolean>(false)
  const [updateCardsValidityDialogOpen, setUpdateCardsValidityDialogOpen] = useState<boolean>(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState<boolean>(false)
  const [selectedCardId, setSelectedCardId] = useState<string>('')
  const [initLoading, setInitLoading] = useState<boolean>(true)
  const [copySuccessOpen, setCopySuccessOpen] = useState<boolean>(false)

  const cards = useSelector((state: RootState) => state.cards.cards)

  const cardsObj = useSelector(store.select.cards.cardsObj)

  const createPersonalCardsLoading = useSelector((state: RootState) => state.loading.effects.cards.createPersonalCards)
  const createAnonymousCardsLoading = useSelector(
    (state: RootState) => state.loading.effects.cards.createAnonymousCards,
  )
  const updateCardLoading = useSelector((state: RootState) => state.loading.effects.cards.updateCard)
  const toggleCardLoading = useSelector((state: RootState) => state.loading.effects.cards.toggleCard)
  const updateCardsValidityLoading = useSelector((state: RootState) => state.loading.effects.cards.updateCardsValidity)
  const getCardsLoading = useSelector((state: RootState) => state.loading.effects.cards.getCards)

  const dispatch = useDispatch<Dispatch>()

  const confirmationDialogRef = useRef<ConfirmationDialogRef>(null)
  const cardsListRef = useRef<CardsListRef>(null)

  const sizes = useWindowSizes()

  const navigate = useNavigate()

  const loading = useMemo(() => {
    return createPersonalCardsLoading || updateCardLoading || createAnonymousCardsLoading
  }, [createAnonymousCardsLoading, createPersonalCardsLoading, updateCardLoading])

  const toggleCreateCardDialog = () => {
    setCreateCardDialogOpen((prevCreateCardDialogOpen) => !prevCreateCardDialogOpen)
  }

  const openUpdateCardsValidityDialog = () => {
    setUpdateCardsValidityDialogOpen(true)
  }

  const closeUpdateCardsValidityDialog = () => {
    setUpdateCardsValidityDialogOpen(false)
  }

  const openCopySuccess = () => {
    setCopySuccessOpen(true)
  }

  const closeCopySuccess = () => {
    setCopySuccessOpen(false)
  }

  const init = useCallback(async () => {
    try {
      await Promise.all([dispatch.cards.getCards(), dispatch.customizations.getCustomizations()])
    } catch (error) {
      console.error(error)
    } finally {
      setInitLoading(false)
    }
  }, [dispatch.cards, dispatch.customizations])

  const handlePersonalCardCreate = async (data: CreateLoyaltyCardBulkContract) => {
    try {
      await dispatch.cards.createPersonalCards(data)

      toggleCreateCardDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleAnonymousCardCreate = async (data: CreateAnonymousLoyaltyCardBulkContract) => {
    try {
      if (data.count > 10) {
        const confirmed = await confirmationDialogRef.current?.show({
          title: 'Are you sure you want to create?',
          description: `You trying to create ${data.count} anonymous cards.`,
        })
        if (!confirmed) {
          return
        }
      }

      await dispatch.cards.createAnonymousCards(data)

      toggleCreateCardDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleCardUpdate = async ({ cardId, data }: { cardId: string; data: UpdateLoyaltyDetailsContract }) => {
    try {
      await dispatch.cards.updateCard({ id: cardId, payload: data })

      setSelectedCardId('')
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleCustomizeClick = useCallback(
    (customizationId: string) => {
      navigate('/customizations', { state: { customizationId } })
    },
    [navigate],
  )

  const handleToggleCardClick = useCallback(
    async (cardId: string) => {
      const card = cardsObj[cardId]
      if (!card || card.isDisabled) {
        return
      }

      try {
        const confirmed = await confirmationDialogRef.current?.show({
          title: 'Are you sure you want to disable this loyalty card?',
          description: "If disabled you can't enable it again.",
        })
        if (!confirmed) {
          return
        }

        await dispatch.cards.toggleCard(card.loyaltyCardId!)
      } catch (error) {
        console.error(error)

        if (!isAxiosError<ErrorContract>(error)) {
          return
        }

        alert(error.response?.data.message)
      }
    },
    [cardsObj, dispatch.cards],
  )

  const handleUpdateCardDialogClose = () => {
    setSelectedCardId('')
  }

  const handleCopyCard = useCallback(async (cardId: string) => {
    try {
      await window.navigator.clipboard.writeText(`${window.location.origin}/cards/${cardId}`)

      openCopySuccess()
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleUpdateCardsValidityDialogUpdate = async (data: Omit<UpdateLoyaltyCardBulkContract, 'ids'>) => {
    const selectedRowIds = cardsListRef.current?.getSelectedRowIds()
    if (!selectedRowIds?.length) {
      alert('First select which cards you want to update.')
      return
    }

    if (selectedRowIds.length > 30) {
      alert('You can update max 30 cards at one time.')
      return
    }

    setLoadingDialogOpen(true)

    try {
      await dispatch.cards.updateCardsValidity({ ...data, ids: selectedRowIds })

      await dispatch.cards.getCards()

      closeUpdateCardsValidityDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    } finally {
      setLoadingDialogOpen(false)
    }
  }

  useEffect(() => {
    init()
  }, [init])

  if (initLoading) {
    return (
      <Box height={sizes.height - HEADER_HEIGHT - 48 * 2} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
        <Button variant="contained" onClick={toggleCreateCardDialog}>
          Create card
        </Button>

        <Box display="flex" flexWrap="wrap" gap={2}>
          <DownloadCSVButton />

          <Button variant="contained" onClick={openUpdateCardsValidityDialog}>
            Update batch
          </Button>
        </Box>
      </Box>

      <Box pt={4}>
        <CardsList
          ref={cardsListRef}
          cards={cards}
          disabled={toggleCardLoading}
          onItemClick={setSelectedCardId}
          onCustomizeClick={handleCustomizeClick}
          onToggleCardClick={handleToggleCardClick}
          onCopyCard={handleCopyCard}
        />
      </Box>

      <CreateCardDialog
        open={createCardDialogOpen}
        loading={loading}
        onPersonalCardCreate={handlePersonalCardCreate}
        onAnonymousCardCreate={handleAnonymousCardCreate}
        onClose={toggleCreateCardDialog}
      />

      <UpdateCardDialog
        selectedCardId={selectedCardId}
        loading={loading}
        onUpdate={handleCardUpdate}
        onClose={handleUpdateCardDialogClose}
      />

      <ConfirmationDialog ref={confirmationDialogRef} />

      <UpdateCardsValidityDialog
        open={updateCardsValidityDialogOpen}
        loading={updateCardsValidityLoading || getCardsLoading}
        onUpdate={handleUpdateCardsValidityDialogUpdate}
        onClose={closeUpdateCardsValidityDialog}
      />

      <LoadingDialog
        msg="Please be patient, update in progress."
        open={loadingDialogOpen}
        onClose={setLoadingDialogOpen}
      />

      <Snackbar
        open={copySuccessOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closeCopySuccess}
      >
        <Alert onClose={closeCopySuccess} severity="info">
          Card url was copied!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Cards
