import { useEffect, useMemo } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useSelector } from 'react-redux'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { LoyaltyDiscountDetailsContract, UpdateLoyaltyDetailsContract, ValidationErrorContract } from '@/types/api'
import { DATE_FORMAT, dayjs } from '@/utilities/dayjs'
import { RootState, store } from '@/utilities/store'
import Form, { FormInputData } from '@/components/Form'
import { setFormErrors } from '@/utilities/functions'

const INITIAL_UPDATE_CARD_FORM_VALUES: UpdateCardFormValues = {
  maxAmount: '',
  orderCount: '',
  dailyOrderCount: '',
  totalAmount: '',
  totalMonthlyAmount: '',
  validTo: '',
  restaurantIds: [],
}

const updateCardSchema: yup.ObjectSchema<UpdateCardFormValues> = yup
  .object({
    dailyOrderCount: yup.string().label('Daily order count'),
    maxAmount: yup.string().label('Max amount'),
    orderCount: yup.string().label('Order count'),
    totalAmount: yup.string().label('Total amount'),
    totalMonthlyAmount: yup.string().label('Total monthly amount'),
    validTo: yup.string().required().label('Valid to'),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
  })
  .required()

interface UpdateCardDialogProps {
  selectedCardId?: string
  loading?: boolean
  onUpdate?: (data: {
    cardId: string
    data: UpdateLoyaltyDetailsContract
  }) => Promise<{ errors: ValidationErrorContract[] } | undefined>
  onClose?: () => void
}

type UpdateCardFormValues = {
  [Property in keyof Omit<UpdateLoyaltyDetailsContract, 'restaurantIds'>]: string
} & { restaurantIds?: string[] }

interface UpdateCardFormData extends FormInputData {
  name: keyof UpdateLoyaltyDetailsContract
}

const UpdateCardDialog = ({ selectedCardId, loading, onUpdate, onClose }: UpdateCardDialogProps) => {
  const restaurants = useSelector((state: RootState) => state.authentication.user?.restaurants ?? [])

  const cardsObj = useSelector(store.select.cards.cardsObj)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<UpdateCardFormValues>({
    resolver: yupResolver(updateCardSchema),
  })

  const card = useMemo(() => {
    if (!selectedCardId) {
      return
    }

    return cardsObj[selectedCardId]
  }, [cardsObj, selectedCardId])

  const updateCardFormData = useMemo(
    (): UpdateCardFormData[] => [
      {
        type: 'multiple-autocomplete-checkbox',
        name: 'restaurantIds',
        label: 'Restaurants',
        items: restaurants.map((restaurant) => ({ value: restaurant.id!, label: restaurant.title ?? '' })),
      },
      {
        type: 'text',
        name: 'maxAmount',
        label: 'Max amount',
        inputType: 'number',
        onlyIntegers: true,
      },
      {
        type: 'text',
        name: 'orderCount',
        label: 'Order count',
        inputType: 'number',
      },
      {
        type: 'text',
        name: 'dailyOrderCount',
        label: 'Daily order count',
        inputType: 'number',
      },
      {
        type: 'text',
        name: 'totalAmount',
        label: 'Total amount',
        disabled: true,
        inputType: 'number',
      },
      {
        type: 'text',
        name: 'totalMonthlyAmount',
        label: 'Total monthly amount',
        inputType: 'number',
        onlyIntegers: true,
      },
      {
        type: 'text',
        name: 'validTo',
        label: 'Valid to',
        inputType: 'datetime-local',
      },
    ],
    [restaurants],
  )

  const onSubmit: SubmitHandler<UpdateCardFormValues> = async (updateFormData) => {
    if (!card) {
      return
    }

    const mappedUpdateFormData = Object.entries<any>(updateFormData).reduce<{ [key: string]: any }>(
      (obj, [key, value]) => {
        if (!value) {
          obj[key] = null
          return obj
        }

        if (Array.isArray(value)) {
          obj[key] = value
          return obj
        }

        if (key === 'validTo') {
          obj[key] = dayjs.utc(value).unix()
          return obj
        }

        obj[key] = Number(value)
        return obj
      },
      {},
    ) as UpdateLoyaltyDetailsContract

    const res = await onUpdate?.({ cardId: card.loyaltyCardId!, data: mappedUpdateFormData })

    setFormErrors(res?.errors, setError)
  }

  useEffect(() => {
    if (!card) {
      return
    }

    const discountData: LoyaltyDiscountDetailsContract = { ...card.discount }
    delete discountData.id

    const mappedDiscountData = Object.entries(discountData)
      .filter(([_, value]) => value !== null)
      .reduce<{ [key: string]: any }>((obj, [key, value]) => {
        if (key === 'validTo') {
          obj[key] = dayjs.utc(value).format(DATE_FORMAT)
          return obj
        }

        obj[key] = String(value)
        return obj
      }, {}) as UpdateCardFormValues

    reset({
      ...INITIAL_UPDATE_CARD_FORM_VALUES,
      ...mappedDiscountData,
      restaurantIds: card.restaurantIds ?? [],
    })
  }, [card, reset])

  return (
    <Dialog fullWidth open={!!card} onClose={onClose}>
      <DialogTitle>Update card{card?.email ? ` - ${card?.email}` : ''}</DialogTitle>

      <DialogContent>
        <Box py={4}>
          <Form control={control} errors={errors} formInputsData={updateCardFormData} loading={loading} />
        </Box>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" disabled={loading} onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateCardDialog
