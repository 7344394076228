import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { createSelector } from 'reselect'

import { RootModel } from '@/models'
import { CreateLoyaltyCardCustomizationContract, LoyaltyCustomizationContract } from '@/types/api'
import { api } from '@/utilities/api'
import { RootState } from '@/utilities/store'
import { CustomizationImgs } from '@/types'

interface CustomizationsState {
  customizations: LoyaltyCustomizationContract[]
}

const initialState: CustomizationsState = {
  customizations: [],
}

export const customizations = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCustomizations(state, customizations: LoyaltyCustomizationContract[]) {
      return { ...state, customizations }
    },
    removeCustomization(state, customizationId: string) {
      return {
        ...state,
        customizations: state.customizations.filter((customization) => customization.id !== customizationId),
      }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getCustomizations() {
      const res: AxiosResponse<LoyaltyCustomizationContract[]> = await api.get('/customization')

      dispatch.customizations.setCustomizations(res.data)
    },
    async createCustomization(payload: CreateLoyaltyCardCustomizationContract) {
      const res: AxiosResponse<LoyaltyCustomizationContract[]> = await api.post('/customization', payload)

      dispatch.customizations.setCustomizations(res.data)
    },
    async updateCustomization({ id, payload }: { id: string; payload: CreateLoyaltyCardCustomizationContract }) {
      const res: AxiosResponse<LoyaltyCustomizationContract[]> = await api.put(`/${id}/update`, payload)

      dispatch.customizations.setCustomizations(res.data)
    },
    async deleteCustomization(customizationId: string) {
      await api.delete(`/customization/${customizationId}`)

      dispatch.customizations.removeCustomization(customizationId)
    },
  }),
})

export const selectCustomizationsObj = createSelector(
  (rootState: RootState) => rootState.customizations,
  (state) =>
    state.customizations.reduce<{ [id: string]: LoyaltyCustomizationContract }>((obj, customization) => {
      obj[customization.id!] = customization

      return obj
    }, {}),
)

export const selectHasUploadedImg = createSelector(
  (rootState: RootState) => rootState.customizations,
  selectCustomizationsObj,
  (_: RootState, props: { customizationId?: string; name: CustomizationImgs }) => props,
  (_, customizationsObj, { customizationId, name }) => {
    if (!customizationId) {
      return false
    }

    const customization = customizationsObj[customizationId]
    if (!customization) {
      return false
    }

    return !!customization[name]
  },
)
