import QRCode from 'react-qr-code'
import styled, { AnyStyledComponent } from 'styled-components'
import { CSSProperties, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'
import { toPng } from 'html-to-image'

import AppleWalletImg from '@/assets/images/apple-wallet.png'
import GoogleWalletImg from '@/assets/images/google-wallet.png'
import { LoyaltyCardDetailsContract } from '@/types/api'
import { replacePlaceholdersWithText } from '@/utilities/functions'
import { INITIAL_FORM_VALUES as CUSTOMIZATION_INITIAL_FORM_VALUES } from '@/pages/Customizations/components/CustomizationDialog'

interface EmailTemplateProps {
  card?: LoyaltyCardDetailsContract
  fontFamily?: CSSProperties['fontFamily']
}

interface TypographyStyledProps {
  fontFamily?: CSSProperties['fontFamily']
}

interface ButtonStyledProps {
  fontFamily?: CSSProperties['fontFamily']
}

const QRCodeStyled = styled(QRCode as unknown as AnyStyledComponent)`
  width: 10rem;
  height: 10rem;
`

const WalletImg = styled.img`
  width: auto;
  height: 100%;
`

const WalletLink = styled.a`
  height: 3.5rem;
`

const TypographyStyled = styled(Typography as AnyStyledComponent)<TypographyStyledProps>`
  white-space: pre-wrap;

  ${({ fontFamily }) =>
    !!fontFamily &&
    `
    font-family: ${fontFamily};
  `}
`

const ButtonStyled = styled(Button as AnyStyledComponent)<ButtonStyledProps>`
  ${({ fontFamily }) =>
    !!fontFamily &&
    `
    font-family: ${fontFamily};
  `}
`

const EmailTemplate = ({ card, fontFamily }: EmailTemplateProps) => {
  const name = card?.customizationProfile?.name ?? ''
  const organizationName = card?.customizationProfile?.organizationName ?? ''
  const email = card?.customizationProfile?.email ?? ''
  const firstHeaderText =
    card?.customizationProfile?.firstHeaderText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.firstHeaderText!
  const firstBodyText = card?.customizationProfile?.firstBodyText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.firstBodyText!
  const secondHeaderText =
    card?.customizationProfile?.secondHeaderText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.secondHeaderText!
  const secondBodyText = card?.customizationProfile?.secondBodyText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.secondBodyText!
  const bottomBodyText = card?.customizationProfile?.bottomBodyText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.bottomBodyText!
  const creditsLeftText =
    card?.customizationProfile?.creditsLeftText ?? CUSTOMIZATION_INITIAL_FORM_VALUES.creditsLeftText!

  const qrCodeRef = useRef<QRCode>(null)

  const handleDownload = async () => {
    if (!qrCodeRef.current) {
      return
    }

    try {
      const dataUrl = await toPng(qrCodeRef.current as unknown as HTMLElement, {
        cacheBust: true,
        width: 480,
        height: 480,
      })

      const link = document.createElement('a')
      link.download = 'loyalty-card.png'
      link.href = dataUrl
      link.click()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box p={2}>
      <TypographyStyled fontFamily={fontFamily} variant="h6" align="center" mb={1}>
        {firstHeaderText}
      </TypographyStyled>

      <TypographyStyled fontFamily={fontFamily} variant="body2" mb={2} align="center">
        {replacePlaceholdersWithText(firstBodyText, [{ cardTitle: name }])}
      </TypographyStyled>

      <TypographyStyled fontFamily={fontFamily} variant="h6" mb={1} align="center">
        {secondHeaderText}
      </TypographyStyled>

      <TypographyStyled fontFamily={fontFamily} variant="body2" mb={2} align="center">
        {replacePlaceholdersWithText(secondBodyText, [{ cardTitle: name }])}
      </TypographyStyled>

      <Box display="flex" flexDirection="column" alignItems="center" py={4} rowGap={2}>
        <TypographyStyled fontFamily={fontFamily} align="center" fontWeight={500}>
          {`${creditsLeftText} ${card?.credits}`}
        </TypographyStyled>

        {!!card?.code && (
          <>
            <QRCodeStyled ref={qrCodeRef} value={card.code} />

            <TypographyStyled fontFamily={fontFamily} align="center" fontWeight={500}>
              {card.code}
            </TypographyStyled>
          </>
        )}

        <ButtonStyled
          fontFamily={fontFamily}
          type="button"
          variant="contained"
          color="success"
          endIcon={<Download />}
          onClick={handleDownload}
        >
          Download
        </ButtonStyled>

        <Box display="flex" flexDirection="column" alignItems="center" pt={4} rowGap={2}>
          {!!card?.appleWalletUrl && (
            <WalletLink href={card.appleWalletUrl} target="_blank">
              <WalletImg src={AppleWalletImg} alt="apple-wallet-img" />
            </WalletLink>
          )}

          {!!card?.googleWalletUrl && (
            <WalletLink href={card.googleWalletUrl} target="_blank">
              <WalletImg src={GoogleWalletImg} alt="google-pay-img" />
            </WalletLink>
          )}
        </Box>
      </Box>

      <TypographyStyled fontFamily={fontFamily} align="center">
        {replacePlaceholdersWithText(bottomBodyText, [
          { organizationTitle: organizationName, organizationEmail: email },
        ])}
      </TypographyStyled>
    </Box>
  )
}

export default EmailTemplate
