import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'
import { isAxiosError } from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

import { Dispatch, RootState } from '@/utilities/store'
import { useWindowSizes } from '@/utilities/hooks'
import { HEADER_HEIGHT } from '@/components/Header'
import { Spinner } from '@/components'
import CustomizationsList from './components/CustomizationsList'
import CustomizationDialog from './components/CustomizationDialog'
import { CreateLoyaltyCardCustomizationContract, ErrorContract } from '@/types/api'

interface LocationState {
  customizationId?: string
}

const Customizations = () => {
  const [initLoading, setInitLoading] = useState<boolean>(true)
  const [customizationDialogOpen, setCustomizationDialogOpen] = useState<boolean>(false)
  const [selectedCustomizationId, setSelectedCustomizationId] = useState<string>('')

  const customizations = useSelector((state: RootState) => state.customizations.customizations)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as LocationState | undefined

  const init = useCallback(async () => {
    try {
      await Promise.all([dispatch.customizations.getCustomizations(), dispatch.fonts.getFonts()])
    } catch (error) {
      console.error(error)
    } finally {
      setInitLoading(false)
    }
  }, [dispatch.customizations, dispatch.fonts])

  const openCustomizationDialog = (customizationId?: string) => {
    if (customizationId) {
      setSelectedCustomizationId(customizationId)
    } else {
      setSelectedCustomizationId('')
    }

    setCustomizationDialogOpen(true)
  }

  const closeCustomizationDialog = () => {
    setCustomizationDialogOpen(false)
  }

  const handleCustomizationUpdate = async ({
    customizationId,
    data,
  }: {
    customizationId: string
    data: CreateLoyaltyCardCustomizationContract
  }) => {
    try {
      await dispatch.customizations.updateCustomization({
        id: customizationId,
        payload: data,
      })

      closeCustomizationDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleCustomizationCreate = async (data: CreateLoyaltyCardCustomizationContract) => {
    try {
      await dispatch.customizations.createCustomization(data)

      closeCustomizationDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      if (!error.response?.data.validationErrors) {
        alert(error.response?.data.message)
        return
      }

      return { errors: error.response.data.validationErrors }
    }
  }

  const handleCustomizationDelete = async (customizationId: string) => {
    try {
      await dispatch.customizations.deleteCustomization(customizationId)

      closeCustomizationDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    const customizationId = locationState?.customizationId
    if (!customizationId) {
      return
    }

    openCustomizationDialog(customizationId)

    navigate(location.pathname, { replace: true })
  }, [location.pathname, locationState?.customizationId, navigate])

  if (initLoading) {
    return (
      <Box height={sizes.height - HEADER_HEIGHT - 48 * 2} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <>
      <Button variant="contained" onClick={() => openCustomizationDialog()}>
        Create customization
      </Button>

      <Box pt={4}>
        <CustomizationsList customizations={customizations} onItemClick={openCustomizationDialog} />
      </Box>

      <CustomizationDialog
        open={customizationDialogOpen}
        selectedCustomizationId={selectedCustomizationId}
        onUpdate={handleCustomizationUpdate}
        onCreate={handleCustomizationCreate}
        onClose={closeCustomizationDialog}
        onDelete={handleCustomizationDelete}
      />
    </>
  )
}

export default Customizations
